/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import QueryString from 'qs';
import Cookies from 'universal-cookie';

/**
 * Customers coming from Google Duplex web panel, should not be promoted to sigin via Chrome Google Sigin
 * query string dgs=true is used to identify the channel of entry
 * eg : https://www.cuspuss.site/red-roses-91790?dgs=true
 */
export const isSkipChromeGoogleSigin = () => {
    let skipSigin = false;

    if (typeof window !== 'undefined') {
        const cookies = new Cookies();

        const cookieSkipChromeSigin = cookies.get('disableGoogleSigin');
        const urlParams = QueryString.parse(window.location.search?.slice(1) || '');

        if (urlParams?.dgs === 'true') {
            cookies.set('disableGoogleSigin', 'true');

            skipSigin = true;
        } else if (cookieSkipChromeSigin === 'true') {
            skipSigin = true;
        }
    }

    return skipSigin;
};

export default {};
